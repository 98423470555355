#RecordingControlsHeader {
  margin: 0.5em;
}

.inputRecording {
    display: flex;
    flex-direction: column;
}

.inputRecording__breadcrumb {
    background-color: #e0e1e2;
    padding: 4px 6px 9px 9px;
    margin-bottom: 10px;
    height: 30px !important;
}

.inputRecording__MainContainer {
    padding:10px;
}

.inputRecording__breadcrumb a {
    font-size: 11px;
}
.inputRecording__breadcrumb .active {
    font-size: 11px;
}
.inputRecording__title {
    margin-top:10px;
    margin-bottom:20px;
    padding-bottom:10px;
    border-bottom:1px solid #cccccc;
    color: #333333;
}
.inputRecording--icon {
    font-size:11px !important;
    margin-right: 10px !important;
}
.inputRecording--icon:last-child {
    margin-right: 0 !important;
}
.inputRecording__table {
    margin-top: 20px;
}
.inputRecording__Row {
    display:flex; 
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.inputRecording--div {
    width: 49%;
    min-height: 400px;
    position: relative;
}

.inputRecording--buttonMenu {
    margin-bottom: 8px;
}

.inputRecording--translationsMenu {
  display: flex;
}

.inputRecording--buttonMargin5 {
    margin-top: 8px;
}

.inputRecording--buttonMargin {
    margin-top: 100px;
}

.inputRecording__listArea {
    margin-top:30px;
}

.blackArea {
    background-color: #333;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 5px transparent;
}

.blackArea.waiting {
    border: solid 5px orange;
}

.blackArea.recording {
    border: solid 5px red;
}

.ui.image#recordingPreview {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.ui.page.modals.transition.visible {
    display: flex !important;
}
